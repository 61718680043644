import { Box, Spinner, useDisclosure } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { Pack, hierarchy } from '@visx/hierarchy'
import { useMeasure } from 'react-use'
import loadable from '@loadable/component'

import useDatabase from 'hooks/useDatabase'
import categories from '../../../google-sheet/categories'
import colors from 'constants/colors'
import CityBubble from 'components/CityBubbles/CityBubble'
import BubbleModal from 'components/CityBubbles/BubbleModal'
import { responsive } from 'contexts/responsive'

const SimpleBarChart = loadable(() => import('./SimpleBarChart'))
// const numFormat = format(".2s")

const colorsByCategory = categories.reduce((all, category, i) => {
  all[category] = colors[i]
  return all
}, {})

const CircleWithDetail = ({ size, color, label, data }) => {
  const { isOpen, onClose, onOpen } = useDisclosure()
  return (
    <>
      <CityBubble size={size} color={color} label={label} onClick={onOpen} />
      <BubbleModal
        isOpen={isOpen}
        onClose={onClose}
        title={label}
      >
        <SimpleBarChart data={data.children} />
      </BubbleModal>
    </>
  )
}

const BubbleChart = ({ data, width, ...props }) => {
  const pack = { children: data, name: 'root' }

  const root = hierarchy(pack)
    .sum((d) => d.value)
    .sort((a, b) => a.value - b.value)

  return (
    <Box style={{ width, height: width }} pos="relative" {...props}>
      <Pack root={root} size={[width, width]}>
        {(packData) => {
          const circles = packData.descendants().filter((d) => d.depth === 1)
          return circles.map((circle, i) => (
            <Box
              key={`circle-${i}`}
              pos="absolute"
              style={{
                left: circle.x - circle.r,
                top: circle.y - circle.r,
              }}
            >
              <CircleWithDetail
                size={`${circle.r * 2}px`}
                color={colorsByCategory[circle.data.name]}
                label={circle.data.name}
                data={circle.data}
              />
            </Box>
          ))
        }}
      </Pack>
    </Box>
  )
}

const Stats = (props) => {
  const [measureRef, { width }] = useMeasure()
  const database = useDatabase()
  const [categoryCount, setCategoryCount] = useState()
  useEffect(() => {
    if (database) {
      const { database: db, ref, onValue } = database
      const statsRef = ref(db, 'cityMatchStats/categories')
      onValue(statsRef, (snapshot) => {
        const data = snapshot.val()
        if (data) {
          const formateed = Object.keys(data).map((name) => {
            const children = Object.entries(data[name]).map(([name, value]) => {
              return {
                name,
                value,
              }
            })
            return {
              name,
              children,
            }
          })
          setCategoryCount(formateed)
        }
      })
    }
  }, [database])
  // console.log(categoryCount)
  return (
    <div ref={measureRef}>
      {typeof categoryCount === 'undefined' ? (
        <Spinner colorScheme="pink" />
      ) : (
        <BubbleChart data={categoryCount} width={width * 0.7} mx="auto" {...props} />
      )}
    </div>
  )
}

export default Stats
